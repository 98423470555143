import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import FunctionsBar from '../../components/Functions-Bar/FunctionsBar';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import profileBitmoji from '../../assets/profile-bitmoji.svg';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import moment from 'moment';
import { AiTwotoneEdit } from 'react-icons/ai';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import {
  DeleteAllCustomersModal,
  DeleteCustomer,
} from '../../components/Modals/Modals';
import ReactPaginate from 'react-paginate';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import spinner from '../../assets/white-spinner.svg';

const Customers = () => {
  const { showProfileModal, baseURL, deleteCustomer, setDeleteCustomer } =
    useGlobalContext();
  const [searchTerm, setSearchTerm] = useState(``);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customerId, setCustomerId] = useState(``);
  const [deleteCustomers, setDeleteCustomers] = useState(false);
  const [usersCount, setUsersCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [exportingUsers, setExportingUsers] = useState(false);

  const navigate = useNavigate();
  const { token } = JSON.parse(sessionStorage.getItem(`adminData`));

  const getAllCustomers = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${baseURL}/users?page=${pageNum}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setCustomers(data.users);
      setUsersCount(data.count);
      sessionStorage.setItem(`customers`, JSON.stringify(data.users));
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllCustomers();
    // eslint-disable-next-line
  }, [pageNum]);

  const exportData = async () => {
    try {
      setExportingUsers(true);
      const { data } = await axios.get(`${baseURL}/users/get-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const headers = Object.keys(data.users[0]).toString();
      const main = data.users.map((item) => Object.values(item).toString());
      const csv = [headers, ...main].join(`\n`);

      const blob = new Blob([csv], { type: `application/csv` });
      const url = URL.createObjectURL(blob);

      const downloadBtn = document.createElement(`a`);
      downloadBtn.download = `users-data.csv`;
      downloadBtn.href = url;
      downloadBtn.style.display = `none`;

      document.body.appendChild(downloadBtn);
      downloadBtn.click();
      downloadBtn.remove();
      URL.revokeObjectURL(url);
      setExportingUsers(false);
    } catch (error) {
      setExportingUsers(false);
      console.log(error);
    }
  };

  // SEARCH CUSTOMERS
  const searchCustomers = () => {
    const tempArray = customers.filter((customer) =>
      customer.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
    setCustomers(tempArray);
    if (searchTerm === ``) {
      getAllCustomers();
    }
  };

  useEffect(() => {
    searchCustomers();
    // eslint-disable-next-line
  }, [searchTerm]);

  const itemsPerPage = 9;
  const pageCount = Math.ceil(usersCount / itemsPerPage);
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
  };

  return (
    <main className='customers-page'>
      <Navbar category={`Management >`} currentPage={`Customers`} />
      <Sidebar activePage={9} />

      <div className={`main-content ${showProfileModal ? `back` : null}`}>
        <FunctionsBar
          pageTitle={`Customers`}
          searchValue={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          searchPlaceholder={`Customer Name`}
        >
          <button className='primary' onClick={() => exportData(customers)}>
            Export Data {exportingUsers && <img src={spinner} alt='' />}
          </button>
          <button className='red' onClick={() => setDeleteCustomers(true)}>
            Delete All
          </button>
        </FunctionsBar>

        <div className='table'>
          <div className='headers'>
            <p>Name</p>
            <p>Email</p>
            <p>Date Registered</p>
            <p>Actions</p>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <>
              {customers &&
                customers.map((user) => {
                  const { name, email, _id: id, createdAt } = user;

                  return (
                    <div className='customer-info' key={id}>
                      <div className='customer'>
                        <div className='img'>
                          <img src={profileBitmoji} alt='profile' />
                        </div>
                        <p className='customer-name'>{name}</p>
                      </div>
                      <p className='email'>{email}</p>
                      <p className='last-payment'>
                        {moment(createdAt).format(`MMMM Do YYYY`)}
                      </p>
                      <div className='btns'>
                        <AiTwotoneEdit
                          className='edit'
                          onClick={() => navigate(`/customers/${id}`)}
                        />
                        <RiDeleteBin2Fill
                          className='delete'
                          onClick={() => {
                            setCustomerId(id);
                            setDeleteCustomer(true);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
            </>
          )}
        </div>

        <ReactPaginate
          breakLabel='...'
          nextLabel={<BsArrowRight />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel={<BsArrowLeft />}
          renderOnZeroPageCount={null}
          containerClassName='pagination'
          pageLinkClassName='page-num'
          activeLinkClassName='active'
          previousClassName='btn back'
          nextClassName='btn forward'
        />
      </div>
      {deleteCustomer && (
        <DeleteCustomer userId={customerId} refreshFunc={getAllCustomers} />
      )}
      {deleteCustomers && (
        <DeleteAllCustomersModal
          dismissFunc={setDeleteCustomers}
          refreshFunc={getAllCustomers}
        />
      )}
    </main>
  );
};

export default Customers;
